import Breadcrumbs from './Breadcrumbs';

window.addEventListener('DOMContentLoaded', () => {
  const $breadcrumbsElements = document.querySelectorAll('.js-breadcrumbs');
  const breadcrumbsList = [];

  if ($breadcrumbsElements.length) {
    $breadcrumbsElements.forEach(($breadcrumbs) => {
      const control = $breadcrumbs.querySelector('.js-breadcrumbs-control');

      const breadcrumbs = new Breadcrumbs($breadcrumbs, {
        isResponsive: true,
      });

      breadcrumbsList.push(breadcrumbs);
      breadcrumbs.mount();
    });
  }
});
