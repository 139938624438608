import 'mdn-polyfills/NodeList.prototype.forEach';
import Toggler from './Toggler';

document.addEventListener('DOMContentLoaded', () => {
  const togglers = document.querySelectorAll('[data-toggler]');
  if (!togglers.length) {
    return;
  }
  togglers.forEach((element) => {
    const toggler = new Toggler(element, document.getElementById(element.dataset.toggler));

    if (window.getComputedStyle(element).getPropertyValue('display') !== 'none') {
      toggler.mount();
    }

    window.addEventListener('resize', (e) => {
      const isDisplayed = window.getComputedStyle(element).getPropertyValue('display') !== 'none';

      if (isDisplayed && !toggler.mounted) {
        toggler.mount();
      } else if (!isDisplayed && toggler.mounted) {
        toggler.unmount();
      }
    });
  });
});
