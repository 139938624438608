import 'mdn-polyfills/NodeList.prototype.forEach';
import DataGouvSearch from '../dataGouv/DataGouv';
import Search from '../search/Search';

export default class Form {
  constructor(element) {
    this.element = element;
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  mount() {
    const dataGouvSearchElement = document.getElementById('autocomplete-datagouv-search');
    if(dataGouvSearchElement) {
      this.dataGouvSearch = new DataGouvSearch(dataGouvSearchElement);
      this.dataGouvSearch.mount();
    }

    const searchElement = document.getElementById('search');
    if (searchElement) {
      this.search = new Search(searchElement);
      this.search.mount();
    }

    this.element.addEventListener('submit', this.handleSubmit);
  }

  handleSubmit(e) {

    if (this.dataGouvSearch.menuOpen) {
      e.preventDefault();
    } else {
    }
  }
}
