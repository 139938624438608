import 'mdn-polyfills/NodeList.prototype.forEach';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

export default class Slider {
  constructor(element) {
    this.element = element;
    this.next = document.querySelector(".swiper-button-next .c-button");
    this.prev = document.querySelector(".swiper-button-prev .c-button");
    this.pagination = document.querySelector(".c-slider__pagination");

    this.swiperElement = this.element;

    this.spacing = 16;
    this.infinite = false;

    this.swiperElement = this.element;

    this.innerWidth = window.innerWidth;

    this.handleResize = this.handleResize.bind(this);
    this.handleResize = this.handleResize.bind(this);

    if (document.querySelector(".c-slider--testimony")) {
      this.infinite = true;
    }

    this.settings = {
      modules: [Navigation, Pagination],
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      loop: this.infinite,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          if (document.querySelector(".c-slider--testimony")) {
            return '<button class="c-button c-button--link ' + className + '"><span class="c-button__label u-hidden-visually">' + document.querySelector(".c-slider__pagination").getAttribute("data-id-"+(index+1)+"") + "</span></button>";
          }else {
            return '<button class="c-button c-button--big c-button--link ' + className + '"><span class="c-button__label">' + document.querySelector(".c-slider__pagination").getAttribute("data-id-"+(index+1)+"") + "</span></button>";
          }
        },
      },
      on: {
        init: (swiper) => {
          this.updateNavigation(swiper);
        },
        slideChange: (swiper) => {
          this.updateNavigation(swiper);
        },
        slideChangeTransitionEnd:(swiper)=> {
          this.updateTabIndex(swiper);
        }
      },
      spaceBetween: 0,
      breakpoints: {
        620: {
          spaceBetween: 20
        },
        690: {
          spaceBetween: 40
        },
        1200: {
          spaceBetween: 120
        },
      }

    };

    this.swiper;
  }

  handleResize() {
  }

  updateTabIndex(){
    let slides = document.querySelectorAll(".swiper-slide");

    for (let i = 0; i < slides.length; i++) {
      const element = slides[i];
      if (element.classList.contains("swiper-slide-active")) {
        let slideButtons=element.querySelectorAll("a");
        for (let index = 0; index < slideButtons.length; index++) {
          const elementButton = slideButtons[index];
          elementButton.setAttribute('tabIndex', '0');
        }
      }else {
        let slideButtons=element.querySelectorAll("a");
        for (let index = 0; index < slideButtons.length; index++) {
          const elementButton = slideButtons[index];
          elementButton.setAttribute('tabIndex', '-1');
        }
      }

    }
  }

  updateNavigation(swiper) {
    if (!document.querySelector(".c-slider--testimony")) {
      this.prev.removeAttribute('disabled');
      this.next.removeAttribute('disabled');

      if (swiper.isBeginning) {
        this.prev.setAttribute('disabled', '');
      }
      if (swiper.isEnd) {
        this.next.setAttribute('disabled', '');
      }
    }

  }



  mount() {
    this.settings = {
      ...this.settings,
    }

    this.swiper = new Swiper(this.swiperElement, this.settings);
    window.addEventListener('resize', this.handleResize);


    if (!document.querySelector(".c-slider--testimony")) {
      this.swiper.on('reachBeginning', () => {
        this.prev.setAttribute('disabled', '');
      });

      this.swiper.on('reachEnd', () => {
        this.next.setAttribute('disabled', '');
      });
    }
  }

  unmount() {
    this.destroy(this.swiper, true);
  }
}
