import 'mdn-polyfills/NodeList.prototype.forEach';
import Toggler from '../toggler/Toggler';

export default class Search {
  constructor(element) {
    this.element = element;
    this.filterBox = document.querySelector(".c-filter");

    this.handelLocation = this.handelLocation.bind(this);
    this.handelLayoutSwitch = this.handelLayoutSwitch.bind(this);
    this.handelAutoComplet = this.handelAutoComplet.bind(this);

    this.handelLayoutRow = this.handelLayoutRow.bind(this);
    this.handelLayoutCard = this.handelLayoutCard.bind(this);
    this.handelUpdateCard = this.handelUpdateCard.bind(this);

    this.geoInput = document.querySelector('.js-location-input');
    this.geoButton = document.querySelector('.js-location-button');

    this.geoLatitude = document.querySelector('.js-latitude');
    this.geoLongitude = document.querySelector('.js-longitude');

    this.layoutCard = document.querySelector('.js-layout-card');
    this.layoutRow = document.querySelector('.js-layout-row');

    this.cardExpended = false;

  }

  mount() {
    if ("geolocation" in navigator) {
      this.geoButton.removeAttribute('aria-hidden');
      this.geoButton.addEventListener('click', this.handelLocation);
    }else {
      this.geoButton.style.display="none";
    }

    if (this.layoutCard) {
      this.layoutCard.addEventListener('click', this.handelLayoutSwitch);
      this.layoutRow.addEventListener('click', this.handelLayoutSwitch);

      if (sessionStorage.getItem("layoutsave")) {
        if (sessionStorage.getItem("layoutsave") == "card") {
          this.handelLayoutCard();
        } else {
          this.handelLayoutRow();
        }
        this.handelUpdateCard();
      }

    }

    this.handelAutoComplet();


  }

  handelAutoComplet(){
    let datalist = document.querySelector(".c-datalist");
    let resultjobInput =document.querySelector(".c-datalist__result");
    let jobInput =document.querySelector(".c-datalist__input");

    // get all new options
    let datalistOptions = document.querySelectorAll(".c-datalist__option");

    jobInput.onfocus = function () {
      datalistOptions.forEach(function(option) {
        // hide datalist on focus
        option.style.display = "none";
        option.setAttribute("tabindex", "-1");
        option.className=" option";
      })
    };

    datalistOptions.forEach(function(option) {
      option.onclick = function () {
        // update selected option variables
        jobInput.value = option.getAttribute("data-value");
        jobInput.value = jobInput.value;

        // close datalist
        datalist.style.display = 'none';
        jobInput.style.borderRadius = "5px";
        jobInput.setAttribute("aria-expanded", "false");

      }
    });

    jobInput.oninput = function() {
      // show datalist
      datalist.style.display ="block";
      datalist.style.display = 'block';
      //jobInput.style.borderRadius = "5px 5px 0 0";

      currentFocus = -1;

      var text = jobInput.value.toUpperCase();

      datalistOptions.forEach(function(option) {

        let valueUppercase = option.getAttribute("data-value").toUpperCase();

        if(valueUppercase.indexOf(text) > -1){
          option.style.display = "block";
          const searchText = jobInput.value;
          option.className=" option option--active";
          option.setAttribute("tabindex", "0");

          // show options just after the 3eme character
          if (jobInput.value.length < 2){
            datalist.style.display="none";
            jobInput.setAttribute("aria-expanded", "false");
          }else {
            datalist.style.display="block";
            jobInput.setAttribute("aria-expanded", "true");
          }

        }else{
          option.style.display = "none";
          option.setAttribute("tabindex", "-1");
          option.className=" option";

          let optionFilterd = document.querySelectorAll(".option--active");
          if (optionFilterd.length > 0) {
          } else {
            datalist.style.display ="none";
            jobInput.setAttribute("aria-expanded", "false");
          }


        }
      });
    }

    var currentFocus = -1;

    // add navigation by arrow
    jobInput.onkeydown = function(e) {
      if(e.keyCode == 40){
        currentFocus++
      addActive();
      }
      else if(e.keyCode == 38){
        currentFocus--
      addActive(datalistOptions);
      }
      else if(e.keyCode == 13){
        e.preventDefault();
        if (currentFocus > -1) {
          let optionFilterd = document.querySelectorAll(".option--active");

          // update selected option variables
          jobInput.value = optionFilterd[currentFocus].getAttribute("data-value");
          jobInput.value = jobInput.value;

          // clode datalist
          datalist.style.display ="none";
          jobInput.setAttribute("aria-expanded", "false");
        }
      }
    }

    // visiual feedback on datalist active
    function addActive() {
      let optionFilterd = document.querySelectorAll(".option--active");
      if (optionFilterd.length === 0) return false;

      removeActive(optionFilterd);

      if (currentFocus >= optionFilterd.length) currentFocus = 0;
      if (currentFocus < 0) currentFocus = (optionFilterd.length - 1);
      optionFilterd[currentFocus].classList.add("active");
    }

    function removeActive(optionFilterd) {
      for (var i = 0; i < optionFilterd.length; i++) {
        optionFilterd[i].classList.remove("active");
      }
    }
  }

  handelLayoutRow() {
    document.querySelector(".js-switch-layout").className="js-switch-layout o-grid--row";
    this.cardExpended = true;
    document.querySelector(".js-layout-row").classList.add("c-button--layout--active");
    document.querySelector(".js-layout-card").classList.remove("c-button--layout--active");
  }

  handelLayoutCard() {
    document.querySelector(".js-switch-layout").className="js-switch-layout o-grid--auto";
    this.cardExpended = false;
    document.querySelector(".js-layout-card").classList.add("c-button--layout--active");
    document.querySelector(".js-layout-row").classList.remove("c-button--layout--active");
  }

  handelLayoutSwitch(event) {
    if (event.target.innerText=="Liste") {
      sessionStorage.setItem("layoutsave", "row");
      this.handelLayoutRow()
    } else {
      sessionStorage.setItem("layoutsave", "card");
      this.handelLayoutCard()
    }
    this.handelUpdateCard();
  }

  handelUpdateCard() {
    let cards = document.querySelectorAll(".c-offer");

    for (let i = 0; i < cards.length; i++) {
      const card = cards[i];
      card.className = this.cardExpended?"c-offer c-offer--expanded ":"c-offer";
    }
  }

  handelLocation() {
    const success = (position) => {
      this.geoInput.value = 'Ma position actuelle';
      this.geoLatitude.value= position.coords.latitude;
      this.geoLongitude.value= position.coords.longitude;
    };

    const error = (position) => {
      this.geoInput.value = 'Impossible de retrouver la localisation';
    };

    navigator.geolocation.getCurrentPosition(success, error);
  }

  unmount() {
  }
}
