import Form from './Form';

document.addEventListener('DOMContentLoaded', () => {
  const formElement = document.querySelector('.c-search-form');

  if (formElement) {
    const form = new Form(formElement);
    form.mount();
  }
});
