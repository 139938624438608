import 'mdn-polyfills/NodeList.prototype.forEach';
import Toggler from '../toggler/Toggler';

export default class Footer {
  constructor(element) {
    this.element = element;
    this.navigationTogglers = [];
  }


  mount() {
    this.element.querySelectorAll('[data-footer-toggler]').forEach((element) => {
      const target = document.getElementById(element.dataset.footerToggler);
      const toggler = new Toggler(element, target, true);

      toggler.mount();
    });
  }


  unmount() {
  }
}
