import 'mdn-polyfills/NodeList.prototype.forEach';

export default class Summary {
  constructor(element) {
    this.element = element;
    this.progressBarBox = element;
    this.progressBar = this.progressBarBox.querySelector(".c-summary__bar__progress");
    this.section = document.querySelector(".o-blocks");
    this.body = document.body;
    this.hasScrolled = true;

    this.handleScroll = this.handleScroll.bind(this);
    this.scrollProgressBar = this.scrollProgressBar.bind(this);


  }

  mount() {
    let innerHeight = window.innerHeight;
    window.addEventListener('scroll', this.handleScroll);
    const loop = () => {
      if (this.hasScrolled) {
        this.scrollProgressBar();
        this.hasScrolled = false;
      }
      // if (this.hasResized) {
      //   this.updateResize();
      //   this.hasResized = false;
      // }

      requestAnimationFrame(loop);
    }

    loop();


    if(!!window.IntersectionObserver){
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {

        let activeText= entry.target.innerText;
        let summeryItems = document.querySelectorAll(".summary__item");

        if(entry.isIntersecting){

          activeText= entry.target.innerText;
          summeryItems = document.querySelectorAll(".summary__item");


          for (let i = 0; i < summeryItems.length; i++) {
            const element = summeryItems[i];
            if (element.innerText == activeText) {
              element.className= "summary__item summary__item--h2 summary__item--active"
            }
          }
          observer.unobserve(entry.target);

        } else {

        }


        });
      }, {rootMargin: "0px 0px " + innerHeight*(-0.5)+"0px"});
      document.querySelectorAll('h2').forEach(headline => { observer.observe(headline) });
    }

    else document.querySelector('#warning').style.display = 'block';


  }

  handleActive() {
    entry.target.classList.add("active");
    let activeText= entry.target.innerText;
    let summeryItems = document.querySelectorAll(".summary__item");



    for (let i = 0; i < summeryItems.length; i++) {
      const element = summeryItems[i];
      if (element.innerText == activeText) {
        element.classList.add("active")
      }
    }
  }

  handleScroll() {
    this.hasScrolled = true;
  }

  scrollProgressBar(){
    let scrollDistance = -(this.section.getBoundingClientRect().top);
    let progressPercentage =
        (scrollDistance /
            (this.section.getBoundingClientRect().height -
                document.documentElement.clientHeight)) * 100;

    let val = Math.floor(progressPercentage);

    if (val < 0) {
      this.progressBar.style.height = '0%';
    } else if (val < 100) {
      this.progressBar.style.height = val + '%';
    }else {
      this.progressBar.style.height = '100%';
    }
  };
}
